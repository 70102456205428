import {
    TRADE_IN_TRANSACTION_GET_LIST,
    TRADE_IN_TRANSACTION_FILTER,
    TRADE_IN_TRANSACTION_CLEAR_FILTER,
    TRADE_IN_TRANSACTION_SORT,
} from "../actions/types";

const INITIAL_STATE = {
    tradeInTransactions: [],
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    tradeInTransactionFilters: {
        _id: '',
        number: '',
        status: [],
        createdAt: {
            from: null,
            to: null
        },
        updatedAt: {
            from: null,
            to: null
        }
    },
}

const tradeInReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case TRADE_IN_TRANSACTION_GET_LIST:
            return {
                ...state,
                tradeInTransactions: action.payload.data
            }
        case TRADE_IN_TRANSACTION_FILTER:
            return {
                ...state,
                tradeInTransactionFilters: {
                    ...state.tradeInTransactionFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case TRADE_IN_TRANSACTION_CLEAR_FILTER:
            return {
                ...state,
                tradeInTransactionFilters: {
                    _id: '',
                    number: '',
                    status: [],
                    createdAt: {
                        from: null,
                        to: null
                    },
                    updatedAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case TRADE_IN_TRANSACTION_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        default:
            return state;
    }
}

export default tradeInReducer;