import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {EXPORT_FILE_CLEAR, EXPORT_FILE_GET, REQUEST_START, SHOW_SUCCESS} from "./types";
import {handleError} from "../utils/utils";

export const exportFile = (exportParams) => async dispatch => {
    dispatch({
        type: REQUEST_START
    })

    axios.post(`${BASE_URL}/v1/export/generateExportCSVFile`, exportParams)
        .then((response) => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.downloadRequest.createdownloadRequestSuccesful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch);
    })
}

export const clearExportFile = () => {
    return({
        type: EXPORT_FILE_CLEAR
    })
}