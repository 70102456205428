import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import { withRouter } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LanguageIcon from '@material-ui/icons/Language';
import {useTranslation} from "react-i18next";
import HomeIcon from "@material-ui/icons/Home";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import BusinessIcon from '@material-ui/icons/Business';
import ViewModuleIcon from "@material-ui/icons/ViewModule";
import MobileScreenShareIcon from "@material-ui/icons/MobileScreenShare";
import SystemUpdateIcon from '@material-ui/icons/SystemUpdate';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuIcon from '@material-ui/icons/Menu';
import CardGiftcardIcon from '@material-ui/icons/CardGiftcard';
import BuildIcon from '@material-ui/icons/Build';
import {
    AUTHORITY_IDENTITY_MANAGER,
    AUTHORITY_PICK_UP_MANAGER,
    AUTHORITY_PICK_UP_OFFICER,
    AUTHORITY_REPAIR_MANAGER,
    AUTHORITY_VIDEO_CONTROLLER,
    AUTHORITY_VOUCHER_CODE_AGENT,
    AUTHORITY_READ_ONLY,
    AUTHORITY_AGENT,
    AUTHORITY_MANAGER,
    SUPPORTED_LANGUAGES,
    AUTHORITY_TRADE_IN_MANAGER,
    AUTHORITY_TRADE_IN_STORE_AGENT,
    AUTHORITY_TRADE_IN_AGENT,
    AUTHORITY_PARTNER_AGENT
} from "../constant";
import {validateAuthority} from "../utils/utils";
import {IconButton} from "@material-ui/core";
import AirportShuttleIcon from '@material-ui/icons/AirportShuttle';
import { AccessibilityNew, AddToQueue } from "@material-ui/icons";
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';

const NavigationBar = ({ userAuthorities, onLogoutClick, onLanguageChange }) => {
    const { t, i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [displayMobileMenu, setDisplayMobileMenu ] = React.useState(false);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const onIconButtonClick = () => {
        setDisplayMobileMenu(!displayMobileMenu);
    }

    const handleClose = (languageCode) => {
        if (typeof languageCode === 'string') {
            onLanguageChange(languageCode)
        }
        setAnchorEl(null);
    };

    return (
        <AppBar position="static">
            <Toolbar className="toolbar">
                <Box display={{ xs: "block", md: "none" }}>
                    <MenuItem variant="h7">
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => onIconButtonClick()}>
                            <MenuIcon />
                        </IconButton>
                    </MenuItem>
                </Box>
                <Box className={`${displayMobileMenu ? 'display-block' : 'display-none'} display-desktop-flex`}>
                    <Box className="display-desktop-flex" flexGrow={1}>
                        <NavLink to="/dashboard" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="homeTab">
                            <MenuItem variant="h7">
                                <HomeIcon />
                                <Box ml={.5}>
                                    <Typography variant="h6" component="h6">
                                        {t('menuItems.home')}
                                    </Typography>
                                </Box>
                            </MenuItem>
                        </NavLink>
                        {
                            validateAuthority(AUTHORITY_IDENTITY_MANAGER, userAuthorities) &&
                            <React.Fragment>
                                <NavLink to="/users" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="usersTab">
                                    <MenuItem variant="h7">
                                        <PeopleAltIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.users')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                                <NavLink to="/businessEntities" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="businessEntitiesTab">
                                    <MenuItem variant="h7">
                                        <BusinessIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.businessEntities')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                            </React.Fragment>
                        }
                        {
                            validateAuthority([AUTHORITY_MANAGER, AUTHORITY_AGENT, AUTHORITY_PARTNER_AGENT], userAuthorities) &&
                            <NavLink to="/subscriptions" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="repairsTab">
                                <MenuItem variant="h7">
                                    <AddToQueue />
                                    <Box ml={.5}>
                                        <Typography variant="h6" component="h6">
                                            {t('menuItems.subscriptions')}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </NavLink>
                        }
                        {
                            validateAuthority([AUTHORITY_TRADE_IN_STORE_AGENT, AUTHORITY_TRADE_IN_AGENT], userAuthorities) &&
                            <React.Fragment>
                                <NavLink to="/tradeInTransactions" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="pickUpsTab">
                                    <MenuItem variant="h7">
                                        <ViewModuleIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.tradeInTransactions')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                            </React.Fragment>
                        }
                        {
                            validateAuthority([AUTHORITY_TRADE_IN_STORE_AGENT, AUTHORITY_TRADE_IN_MANAGER], userAuthorities) &&
                            <React.Fragment>
                                <NavLink to="/tradeInDashboard" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="pickUpsTab">
                                    <MenuItem variant="h7">
                                        <MobileScreenShareIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.tradeInDashboard')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                            </React.Fragment>
                        }
                        {
                            validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], userAuthorities) &&
                            <React.Fragment>
                                <NavLink to="/pickUps" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="pickUpsTab">
                                    <MenuItem variant="h7">
                                        <ViewModuleIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.pickUps')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                                <NavLink to="/pickUpItems" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="pickUpItemsTab">
                                    <MenuItem variant="h7">
                                        <MobileScreenShareIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.pickUpItems')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                            </React.Fragment>
                        }
                         {
                            validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER, AUTHORITY_VIDEO_CONTROLLER], userAuthorities) &&
                            <React.Fragment>
                                <NavLink to="/downloadRequests" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="downloadRequestsTab">
                                    <MenuItem variant="h7">
                                        <SystemUpdateIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.downloadRequests')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                            </React.Fragment>
                        }
                        {
                            validateAuthority([AUTHORITY_PICK_UP_OFFICER], userAuthorities) &&
                            <NavLink to="/boxDeliveries" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="boxDeliveriesTab">
                                <MenuItem variant="h7">
                                    <AirportShuttleIcon />
                                    <Box ml={.5}>
                                        <Typography variant="h6" component="h6">
                                            {t('menuItems.boxDeliveries')}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </NavLink>
                        }
                        {
                            validateAuthority(AUTHORITY_VOUCHER_CODE_AGENT, userAuthorities) &&
                                <NavLink to="/voucherCodes" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="voucherCodesTab">
                                    <MenuItem variant="h7">
                                        <CardGiftcardIcon />
                                        <Box ml={.5}>
                                            <Typography variant="h6" component="h6">
                                                {t('menuItems.voucherCodes')}
                                            </Typography>
                                        </Box>
                                    </MenuItem>
                                </NavLink>
                        }
                        {
                            validateAuthority(AUTHORITY_REPAIR_MANAGER, userAuthorities) &&
                            <NavLink to="/repairs" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="repairsTab">
                                <MenuItem variant="h7">
                                    <BuildIcon />
                                    <Box ml={.5}>
                                        <Typography variant="h6" component="h6">
                                            {t('menuItems.repairs')}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </NavLink>
                        }
                        {
                            validateAuthority([AUTHORITY_MANAGER, AUTHORITY_AGENT, AUTHORITY_PARTNER_AGENT], userAuthorities) &&
                            <NavLink to="/customers" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="customersTab">
                                <MenuItem variant="h7">
                                    <AccessibilityNew />
                                    <Box ml={.5}>
                                        <Typography variant="h6" component="h6">
                                            {t('menuItems.customers')}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </NavLink>
                        }
                        {
                            validateAuthority([AUTHORITY_MANAGER, AUTHORITY_AGENT, AUTHORITY_PARTNER_AGENT], userAuthorities) &&
                            <NavLink to="/extendedWarrantyClaims" style={{ textDecoration: 'none', color: '#fff' }} seleniumselector="extendedWarrantyClaimsTab">
                                <MenuItem variant="h7">
                                    <PhoneAndroidIcon />
                                    <Box ml={.5}>
                                        <Typography variant="h6" component="h6">
                                            {t('menuItems.extendedWarrantyClaims')}
                                        </Typography>
                                    </Box>
                                </MenuItem>
                            </NavLink>
                        }
                    </Box>
                    {
                        SUPPORTED_LANGUAGES.length > 1 && <React.Fragment>
                            <MenuItem variant="h7" onClick={handleClick}>
                                <LanguageIcon />
                                <Box ml={.5}>
                                    <Typography variant="h6" component="h6">
                                        {t(`values.languageCode.${i18n.language}`)}
                                    </Typography>
                                </Box>
                            </MenuItem>
                            <Menu
                                id="fade-menu"
                                anchorEl={anchorEl}
                                keepMounted
                                open={open}
                                onClose={handleClose}
                            >
                                {
                                    SUPPORTED_LANGUAGES.map(supportedLanguage => {
                                        return (
                                            <MenuItem key={supportedLanguage} onClick={() => handleClose(supportedLanguage)}>{t(`values.languageCode.${supportedLanguage}`)}</MenuItem>
                                        )
                                    })
                                }
                            </Menu>
                        </React.Fragment>
                    }
                    <NavLink to="/myAccount" style={{ textDecoration: 'none', color: '#fff' }} className="display-flex" seleniumselector="myAccountTab">
                        <MenuItem variant="h7" component="h6">
                            <AccountCircleIcon />
                        </MenuItem>
                    </NavLink>
                    <MenuItem variant="h7" onClick={onLogoutClick} seleniumselector="logoutTab">
                        <ExitToAppIcon />
                        <Box ml={.5}>
                            <Typography variant="h6" component="h6">
                                {t('menuItems.logout')}
                            </Typography>
                        </Box>
                    </MenuItem>
                </Box>
            </Toolbar>
        </AppBar>
    )
}

export default withRouter(NavigationBar);