import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    TRADE_IN_TRANSACTION_GET_LIST,
    TRADE_IN_TRANSACTION_FILTER,
    TRADE_IN_TRANSACTION_CLEAR_FILTER,
    TRADE_IN_TRANSACTION_SORT,
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getTradeInTransactions = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/tradeIn/?${path}`)
        .then(response => {
            dispatch({
                type: TRADE_IN_TRANSACTION_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateFiltersForTradeInTransactions = (filterKey, filterValue) => {
    return ({
        type: TRADE_IN_TRANSACTION_FILTER,
        payload: { filterKey, filterValue }
    })
}

export const clearTradeInTransactionsFilters = () => {
    return ({
        type: TRADE_IN_TRANSACTION_CLEAR_FILTER
    })
}

export const sortTradeInTransactions = (orderBy, sort) => {
    return ({
        type: TRADE_IN_TRANSACTION_SORT,
        payload: { orderBy, sort }
    })
}