import React from 'react';
import {Dialog, DialogActions, DialogContent, DialogTitle} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import Button from "@material-ui/core/Button";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";

const customStyles = makeStyles({
    info: {
        background: '#00BAC7',
        width: '100%',
        height: 10
    }
})

const ConfirmationDialogSimple = ({entity, action, open, onReject, onAccept}) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const [disabled, setDisabled] = React.useState(false);

    const handleClose = () => {
        onReject();
    };

    const handleAccept = () => {
        setDisabled(true);
        onAccept();
    }

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            transitionDuration={{
                enter: 1,
                exit: 1
            }}
        >
            <div className={classes.info}/>
            <DialogTitle>{t('confirmationMessages.title')}</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    {t(`confirmationMessages.${entity}.${action}`)}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="outlined" color="primary">
                    {t('confirmationMessages.cancel')}
                </Button>
                <Button onClick={handleAccept} variant="outlined" color="primary" disabled={disabled}>
                    {t('confirmationMessages.yes')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmationDialogSimple;