const pickUpItemsFields = {
    "name": "pickUpItemsInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUpItems",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "imei",
            "displayName": "IMEI",
            "exportArray": null
        }, {
            "fieldName": "isBooked",
            "displayName": "Device is booked",
            "exportArray": null
        }, {
            "fieldName": "type",
            "displayName": "Type",
            "exportArray": null
        }]
    }, {
        "collection": "articles",
        "primaryKey": "articleId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "description",
            "displayName": "Description",
            "exportArray": null
        }]
    }, {
        "collection": "pickUps",
        "primaryKey": "pickUpId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "number",
            "displayName": "Order Number",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pick-Up Date",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

const pickUpItemsAdditionalFields = {
    "name": "pickUpItemsInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUpItems",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "imei",
            "displayName": "IMEI",
            "exportArray": null
        }, {
            "fieldName": "isBooked",
            "displayName": "Device is booked",
            "exportArray": null
        }, {
            "fieldName": "deviceCondition",
            "displayName": "Device Condition",
            "exportArray": null
        }, {
            "fieldName": "type",
            "displayName": "Type",
            "exportArray": null
        }]
    }, {
        "collection": "articles",
        "primaryKey": "articleId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "description",
            "displayName": "Description",
            "exportArray": null
        }]
    }, {
        "collection": "pickUps",
        "primaryKey": "pickUpId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "number",
            "displayName": "Order Number",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pick-Up Date",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

const pickUpExportFields = {
    "name": "pickUpOrderInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUps",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "number",
            "displayName": "Pickup Order Code",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Pickup Order Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pickup Date",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "logistics_deliveries",
        "primaryKey": "deliveryId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "courierAdapterCode",
            "displayName": "Courier",
            "exportArray": null
        }, {
            "fieldName": "tracking.trackingNumber",
            "displayName": "AWB Courier Number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

const pickUpExportAdditionalFields = {
    "name": "pickUpOrderInfo",
    "exportOption": "monthly",
    "mainCollection": "pickUps",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": "id",
            "exportArray": null
        },{
            "fieldName": "number",
            "displayName": "Pickup Order Code",
            "exportArray": null
        }, {
            "fieldName": "status",
            "displayName": "Pickup Order Status",
            "exportArray": null
        }, {
            "fieldName": "pickUpDate",
            "displayName": "Pickup Date",
            "exportArray": null
        }, {
            "fieldName": "parcelCondition",
            "displayName": "Parcel Condition",
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": "Created At of pick up number",
            "exportArray": null
        }]
    }, {
        "collection": "logistics_deliveries",
        "primaryKey": "deliveryId",
        "foreignKey": "_id",
        "exportFields": [{
            "fieldName": "courierAdapterCode",
            "displayName": "Courier",
            "exportArray": null
        }, {
            "fieldName": "tracking.trackingNumber",
            "displayName": "AWB Courier Number",
            "exportArray": null
        }]
    }, {
        "collection": "businessentities",
        "primaryKey": "businessEntityCode",
        "foreignKey": "code",
        "exportFields": [{
            "fieldName": "code",
            "displayName": "Business Entity Code",
            "exportArray": null
        }, {
            "fieldName": "name",
            "displayName": "Business Entity Name",
            "exportArray": null
        }, {
            "fieldName": "dealerCode",
            "displayName": "Dealer Code",
            "exportArray": null
        }, {
            "fieldName": "aggregatedPOSCode",
            "displayName": "Aggregated POS Code",
            "exportArray": null
        },{
            "fieldName": "email",
            "displayName": "Email",
            "exportArray": null
        }, {
            "fieldName": "address.street",
            "displayName": "Street",
            "exportArray": null
        }, {
            "fieldName": "address.number",
            "displayName": "Number",
            "exportArray": null
        }, {
            "fieldName": "address.zipCode",
            "displayName": "ZIP Code",
            "exportArray": null
        }, {
            "fieldName": "address.city",
            "displayName": "City",
            "exportArray": null
        }, {
            "fieldName": "address.provinceCode",
            "displayName": "Province Code",
            "exportArray": null
        }]
    }]
}

export const remarksExport = {
    "name": "remarks",
    "exportOption": "monthly",
    "mainCollection": "remarks",
    "exportItems": [{
        "collection": null,
        "primaryKey": null,
        "foreignKey": null,
        "exportFields": [{
            "fieldName": "_id",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "countryCode",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "creatorId",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "creatorDisplayName",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "content",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "entityName",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "entityId",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "createdAt",
            "displayName": null,
            "exportArray": null
        }, {
            "fieldName": "updatedAt",
            "displayName": null,
            "exportArray": null
        }]
    }]   
};

export const boxDeliveriesExport = () => {
    return (
        {
            "name": "logistics_deliveries",
            "exportOption": "monthly",
            "mainCollection": "logistics_deliveries",
            "exportItems": [
                {
                    "collection": null,
                    "primaryKey": null,
                    "foreignKey": null,
                    "filterField": [
                        "usageType"
                    ],
                    "filterValue": [
                        "boxOrder"
                    ],
                    "exportFields": [
                        {
                            "fieldName": "salesOrderNumber",
                            "displayName": "Sales Order Number",
                            "exportArray": null
                        },
                        {
                            "fieldName": "businessEntity",
                            "displayName": "Business Entity Code",
                            "exportArray": null
                        },
                        {
                            "fieldName": "deliveryItems",
                            "displayName": "Quantity",
                            "exportArray": {
                                "arrayFields": [
                                    "quantity"
                                ],
                                "arrayIndex": "0"
                            }
                        },
                        {
                            "fieldName": "deliveryItems",
                            "displayName": "Status",
                            "exportArray": {
                                "arrayFields": [
                                    "status"
                                ],
                                "arrayIndex": "0"
                            }
                        },
                        {
                            "fieldName": "createdAt",
                            "displayName": "Created At",
                            "exportArray": null
                        }
                    ]
                }
            ]
        })
};

export const pickUpsExportParams = (authorities) => {
    if (authorities.includes('PICK_UP_OFFICER')) {
        return pickUpExportFields;
    } else if (authorities.includes('PICK_UP_MANAGER')) {
        return pickUpExportAdditionalFields;
    } else if (authorities.includes('VIDEO_CONTROLLER')) {
        return pickUpExportAdditionalFields;
    }
}

export const pickUpItemsExportParams = (authorities) => {
    if (authorities.includes('PICK_UP_OFFICER')) {
        return pickUpItemsFields;
    } else if (authorities.includes('PICK_UP_MANAGER')) {
        return pickUpItemsAdditionalFields;
    } else if (authorities.includes('VIDEO_CONTROLLER')) {
        return pickUpItemsAdditionalFields;
    }
}