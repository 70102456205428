import React, {Component} from "react";
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from "react-router-dom";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import NavigationBar from "../components/NavigationBar";
import themeBolttech from "../theme";
import UserList from "./UserList";
import UserDetails from "./UserDetails";
import LoginPage from "./LoginPage";
import {
    changePassword, getOtp,
    logIn,
    logOut,
    sendForgotPasswordEmail, setNewPasswordRequest,
    setResetPasswordToken,
    submitForgotPasswordConfirmation,
} from "../actions/authenticationActions";
import i18n from "i18next";
import SimpleDialog from "../components/SimpleDialog";
import {simpleDialogClose, showError, hideConfirmationDialog} from "../actions/commonActions";
import Dashboard from "./Dashboard";
import PickUpList from "./PickUpList";
import PickUpDetails from "./PickUpDetails";
import PickUpItemList from "./PickUpItemList";
import UserCreate from "./UserCreate";
import ForgotPassword from "./ForgotPassword";
import PasswordReset from "./PasswordReset";
import * as queryString from "query-string";
import ChangePassword from "./ChangePassword";
import BusinessEntityList from "./BusinessEntityList";
import BusinessEntityDetails from "./BusinessEntityDetails";
import BusinessEntityCreate from "./BusinessEntityCreate";
import ConfirmationDialog from "../components/ConfirmationDialog";
import MyAccount from "./MyAccount";
import {updatePickUpDate} from "../actions/pickUpActions";
import {resetMerchantUserPassword} from "../actions/merchantUserActions";
import {updateRepairParts, updateRepairCost, updateStatus} from "../actions/swapActions";
import VoucherCodeList from "./VoucherCodeList";
import VoucherCodeDetails from "./VoucherCodeDetails";
import SetNewPassword from "./SetNewPassword";
import RepairList from "./RepairList";
import RepairDetails from "./RepairDetails";
import BoxDeliveryList from "./BoxDeliveryList";
import BoxDeliveryDetails from "./BoxDeliveryDetails";
import DownloadRequestList from "./DownloadRequestList";
import SubscriptionList from "./SubscriptionList";
import CustomerList from "./CustomerList";
import ExtendedWarrantyClaimList from "./ExtendedWarrantyClaimList";
import ExtendedWarrantyDetails from "./ExtendedWarrantyDetails";
import CustomerDetails from "./CustomerDetails";
import SubscriptionDetails from "./SubscriptionDetails";
import TradeInTransactionsList from "./TradeInTransactionsList";

const handleLanguageChange = (languageCode, i18n) => {
    i18n.changeLanguage(languageCode)
}

class Main extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showDashboard: false,
        };
    }

    componentDidMount() {
        const pathname = this.props.location.pathname;
        const showDashboard = pathname === "/" || pathname.endsWith("dashboard");
        this.setState({ showDashboard });
        !this.props.authentication.authenticated && !pathname.startsWith('/forgotPasswordConfirmation') && pathname !== '/forgotPassword' && pathname !== '/setNewPassword' && this.props.history.push('/login');
        this.props.authentication.passwordExpired === 'true' && this.props.history.push('/changePassword');
        this.props.authentication.authenticated && this.props.location.pathname === '/' && this.props.history.push('/dashboard');
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.authentication.authenticated !== prevProps.authentication.authenticated &&
            this.props.authentication.authenticated === true
        ) {
            this.props.authentication.authenticated && this.props.history.push('/dashboard');
        }

        if (
            this.props.authentication.passwordExpired !== prevProps.authentication.passwordExpired &&
            this.props.authentication.passwordExpired === 'true'
        ) {
            this.props.authentication.passwordExpired === 'true' && this.props.history.push('/changePassword');
        }
    }

    renderLoginPage(message) {
        return (
            <LoginPage
                onFormSubmit={data => this.props.logIn(data)}
                errorMessage={message}
            />
        )
    }

    renderForgotPasswordPage() {
        return (
            <ForgotPassword
                onFormSubmit={data => this.props.sendForgotPasswordEmail(data)}
                errorMessage={this.props.authentication.errorMessage}
                requestInProgress={this.props.common.requestInProgress}
                requestSuccess={this.props.common.requestSuccess}
            />
        )
    }

    renderPasswordResetPage() {
        const parsedQueryString = queryString.parse(this.props.location.search);
        return (
            <PasswordReset onGetOtp={data => this.props.getOtp(data)}
                           onFormSubmit={data => this.props.submitForgotPasswordConfirmation(data)}
                           errorMessage={this.props.authentication.errorMessage}
                           resetPasswordToken={parsedQueryString.token}
                           otpId={this.props.authentication.forgetPasswordConfirmationRequest.otpId}
                           requestInProgress={this.props.common.requestInProgress}
                           requestSuccess={this.props.common.requestSuccess}
            />
        )
    }

    renderSetNewPasswordPage() {
        const parsedQueryString = queryString.parse(this.props.location.search);
        return (
            <SetNewPassword onFormSubmit={data => this.props.setNewPasswordRequest(data)}
                            errorMessage={this.props.authentication.errorMessage}
                            setNewPasswordToken={parsedQueryString.token}
                            requestInProgress={this.props.common.requestInProgress}
                            requestSuccess={this.props.common.requestSuccess}
            />
        )
    }

    renderChangePasswordPage() {
        return (
            <ChangePassword onFormSubmit={data => this.props.changePassword(data)}
                            onLogOut={() => this.handleLogOut()}
                            errorMessage={this.props.authentication.errorMessage}
                            requestInProgress={this.props.common.requestInProgress}
                            requestSuccess={this.props.common.requestSuccess}
            />
        )
    }

    handleLogOut() {
        this.props.logOut();
        this.props.history.push('/login')
    }

    handleDialogClose() {
        this.props.simpleDialogClose();
    }

    render() {
        const pathname = this.props.location.pathname;
        const showDashboard = pathname === '/' || pathname.endsWith('dashboard');
        const showForgotPassword = pathname === '/forgotPassword';
        const showPasswordReset = pathname === '/forgotPasswordConfirmation';
        const showSetNewPassword = pathname === '/setNewPassword';
        return (
            <MuiThemeProvider theme={themeBolttech}>
                {this.props.authentication.authenticated && this.props.authentication.passwordExpired === 'false' && (
                    <div>
                        <SimpleDialog open={this.props.common.dialogOpen}
                                      message={this.props.common.dialogMessage}
                                      dialogType={this.props.common.dialogType}
                                      entity={this.props.common.entity}
                                      handleDialogClosing={() => this.handleDialogClose()}
                                      uploadResponseMessage={this.props.common.uploadResponseMessage}
                        />
                        <ConfirmationDialog entity={this.props.common.confirmationDialog.entity}
                                            field={this.props.common.confirmationDialog.field}
                                            action={this.props.common.confirmationDialog.action}
                                            open={this.props.common.confirmationDialog.open}
                                            previousValue={this.props.common.confirmationDialog.previousValue}
                                            newValue={this.props.common.confirmationDialog.newValue}
                                            valueType={this.props.common.confirmationDialog.valueType}
                                            countryCode={this.props.authentication.loggedInUser.countryCode}
                                            onReject={() => this.props.hideConfirmationDialog()}
                                            onAccept={() => this.props[this.props.common.confirmationDialog.onAccept](this.props.common.confirmationDialog.entityId, this.props.common.confirmationDialog.newValue)}
                        />
                        <NavigationBar userAuthorities={this.props.authentication.loggedInUser.authorities}
                                       onLogoutClick={() => this.props.logOut()}
                                       languageCode={this.props.authentication.loggedInUser.languageCode}
                                       onLanguageChange={(languageCode) => handleLanguageChange(languageCode, i18n)} />
                        <div>
                            {showDashboard && <Dashboard />}
                            <Switch>
                                <Route exact path="/forgotPassword" component={ForgotPassword} />
                                <Route exact path="/setNewPassword" component={SetNewPassword} />
                                <Route exact path="/myAccount" component={MyAccount} />
                                <Route exact path="/users" component={UserList} />
                                <Route path="/users/:id" component={UserDetails} />
                                <Route exact path="/usersNew" component={UserCreate} />
                                <Route exact path="/subscriptions" component={SubscriptionList} />
                                <Route exact path="/subscriptions/:id" component={SubscriptionDetails} />
                                <Route exact path="/businessEntities" component={BusinessEntityList} />
                                <Route exact path="/businessEntities/:id" component={BusinessEntityDetails} />
                                <Route exact path="/businessEntitiesNew" component={BusinessEntityCreate} />
                                <Route exact path="/extendedWarrantyClaims" component={ExtendedWarrantyClaimList} />
                                <Route exact path="/extendedWarrantyClaims/:id" component={ExtendedWarrantyDetails} />
                                <Route exact path="/customers" component={CustomerList} />
                                <Route exact path="/customers/:id" component={CustomerDetails} />
                                <Route exact path="/pickUps" component={PickUpList} />
                                <Route path="/pickUps/:id" component={PickUpDetails} />
                                <Route exact path="/pickUpItems" component={PickUpItemList} />
                                <Route exact path="/downloadRequests" component={DownloadRequestList} />
                                <Route exact path="/repairs" component={RepairList} />
                                <Route path="/repairs/:id" component={RepairDetails} />
                                <Route exact path="/voucherCodes" component={VoucherCodeList} />
                                <Route path="/voucherCodes/:id" component={VoucherCodeDetails} />
                                <Route exact path="/boxDeliveries" component={BoxDeliveryList} />
                                <Route path="/boxDeliveries/:id" component={BoxDeliveryDetails} />
                                <Route exact path="/tradeInTransactions" component={TradeInTransactionsList} />
                                <Redirect to="/dashboard" />
                            </Switch>
                        </div>
                    </div>
                )}
                {!this.props.authentication.authenticated && !showForgotPassword && !showPasswordReset && !showSetNewPassword && this.renderLoginPage(this.props.authentication.errorMessage)}
                {!this.props.authentication.authenticated && showForgotPassword && !showPasswordReset && this.renderForgotPasswordPage()}
                {!this.props.authentication.authenticated && showPasswordReset && this.renderPasswordResetPage()}
                {!this.props.authentication.authenticated && showSetNewPassword && this.renderSetNewPasswordPage()}
                {this.props.authentication.passwordExpired === 'true' && this.renderChangePasswordPage()}
            </MuiThemeProvider>
        );
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    logIn,
    logOut,
    sendForgotPasswordEmail,
    getOtp,
    setResetPasswordToken,
    submitForgotPasswordConfirmation,
    setNewPasswordRequest,
    simpleDialogClose,
    hideConfirmationDialog,
    showError,
    updatePickUpDate,
    updateRepairParts,
    updateRepairCost,
    updateStatus,
    changePassword,
    resetMerchantUserPassword
})(Main);
