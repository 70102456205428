import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { extend } from "lodash";

const UpdateStatusView = (props) => {
  const { extendedWarrantyClaim, onExtendedWarrantyClaimUpdateStatus, onInitiateExtendedWarrantyClaim } =
    props;
  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  const customStyleClass = customStyles();

  const formik = useFormikContext();

  const beforeQuotationStatus = [
    "pendingInternal",
    "pendingForCollection",
    "deliveringToAsc",
    "deliveredToAsc",
    "pendingCustomerQuotation"
  ]

  const beforeInvoiceStatus = [
    "proceedWithRepair",
    "returnFromAsc",
    "returnDelivering",
    "returnedToCustomer"
  ]

  const paymentStatus = [
    "reimbursementRequested",
    "paymentInitiated",
    "paymentInProgress"
  ]

  const handleUpdateStatus = (payload) => {
    onExtendedWarrantyClaimUpdateStatus(payload)
  }

  const initiateExtendedWarrantyClaims = (values) => {
    onInitiateExtendedWarrantyClaim(values);
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-location-arrow" aria-hidden="true"></i>
              <strong> {'Update Status'}</strong>
            </div>
            <div className="card-body">
              <div className="row">

                {extendedWarrantyClaim?.status == "new" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        onClick={() => initiateExtendedWarrantyClaims(formik.values)}
                        className="btn btn-sm btn-primary"
                        type="button">
                        <i class="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Initiate EW Claim"}
                      </button>
                    </div>
                  </div>
                )}

                {beforeQuotationStatus.includes(extendedWarrantyClaim?.status) && (
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>{'Update Status'}</label>
                      <select
                        id="extendedWarrantyClaim.status.beforeQuotation"
                        className="form-control form-control-sm"
                        onChange={(event) => handleUpdateStatus({ values: formik.values, status: event.target.value, isSaveRequired: true })}
                      >
                        <option selected={extendedWarrantyClaim?.status == "pendingInternal"} value="setToPendingInternal">{'Pending Internal'}</option>
                        <option selected={extendedWarrantyClaim?.status == "pendingForCollection"} value="setToPendingForCollection">{'Pending For Collection'}</option>
                        <option selected={extendedWarrantyClaim?.status == "deliveringToAsc"} value="setToDeliveringToAsc">{'Delivering To ASC'}</option>
                        <option selected={extendedWarrantyClaim?.status == "deliveredToAsc"} value="setToDeliveredToAsc">{'Delivered To ASC'}</option>
                        <option selected={extendedWarrantyClaim?.status == "pendingCustomerQuotation"} value="setToPendingCustomerQuotation">{'Pending Customer Quotation'}</option>
                        <option selected={extendedWarrantyClaim?.status == "quotationReceivedAndUploaded"} value="setToQuotationReceivedAndUploaded">{'Quotation Received and Uploaded'}</option>
                      </select>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "quotationReviewInProgress" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="confirm-quotation-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToQuotationAwaitingCustomerResponse', isSaveRequired: true })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Confirm Quotation"}
                      </button>
                      {' '}
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="quotation-not-required-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToInvoiceReviewInProgress', isSaveRequired: true })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Quotation Not Required"}
                      </button>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "quotationAwaitingCustomerResponse" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="accept-quotation-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToCustomerAcceptedQuotation', isSaveRequired: true })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Accept Quotation"}
                      </button>
                      {' '}
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="decline-quotation-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToCustomerDeclinedQuotation', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Decline Quotation"}
                      </button>
                      {' '}
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="dispute-quotation-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToCustomerDisputedQuotation', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Dispute Quotation"}
                      </button>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "approved" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="proceed-with-repair-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToProceedWithRepair', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Proceed With Repair"}
                      </button>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "pendingPayment" && (
                  < div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="back-to-quotation-awaiting-customer-response-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToQuotationAwaitingCustomerResponse', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Back to Quotation Awaiting Customer Response"}
                      </button>
                    </div>
                  </div>
                )}

                {beforeInvoiceStatus.includes(extendedWarrantyClaim?.status) && (
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>{" Update Status"}</label>
                      <select
                        id="extendedWarrantyClaim.status.beforeInvoice"
                        className="form-control form-control-sm"
                        onChange={(event) => handleUpdateStatus({ values: formik.values, status: event.target.value, isSaveRequired: false })}
                      >
                        <option selected={extendedWarrantyClaim?.status == "proceedWithRepair"} value="setToProceedWithRepair">{"Proceed With Repair"}</option>
                        <option selected={extendedWarrantyClaim?.status == "returnFromAsc"} value="setToReturnFromAsc">{"Return From Asc"}</option>
                        <option selected={extendedWarrantyClaim?.status == "returnDelivering"} value="setToReturnDelivering">{"Return Delivering"}</option>
                        <option selected={extendedWarrantyClaim?.status == "returnedToCustomer"} value="setToReturnedToCustomer">{"Returned To Customer"}</option>
                        <option selected={extendedWarrantyClaim?.status == "repairInvoiceReceivedAndUploaded"} value="setToRepairInvoiceReceivedAndUploaded">{"Repair Invoice Received And Uploaded"}</option>
                      </select>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "invoiceReviewInProgress" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="confirm-invoice-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToInvoiceAwaitingCustomerResponse', isSaveRequired: true })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Confirm Invoice"}
                      </button>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "invoiceAwaitingCustomerResponse" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="accept-invoice-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToCustomerAcceptedInvoice', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Accept Invoice"}
                      </button>
                      {' '}
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="decline-invoice-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToCustomerDeclinedInvoice', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Decline Invoice"}
                      </button>
                      {' '}
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="dispute-invoice-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToCustomerDisputedInvoice', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Dispute Invoice"}
                      </button>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "customerAcceptedInvoice" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="servicing-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToServicing', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Servicing"}
                      </button>
                      {' '}
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="reimbursement-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToReimbusement', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Reimbursement"}
                      </button>
                    </div>
                  </div>
                )}

                {(extendedWarrantyClaim?.status == "servicing" || extendedWarrantyClaim?.status == "paymentCompleted") && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="final-review-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToFinalReview', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Final Review"}
                      </button>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.status == "reimbursement" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="reimbursement-requested-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToReimbursementRequested', isSaveRequired: false })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Reimbursement Requested"}
                      </button>
                    </div>
                  </div>
                )}

                {(extendedWarrantyClaim?.allowManualUpdatePaymentStatus || extendedWarrantyClaim?.beneficiary?.paymentMethod != "bankTransfer") && paymentStatus.includes(extendedWarrantyClaim?.status) && (
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label>{"Update Status"}</label>
                      <select
                        id="extendedWarrantyClaim.status.payment"
                        className="form-control form-control-sm"
                        onChange={(event) => handleUpdateStatus({ values: formik.values, status: event.target.value, isSaveRequired: false })}
                      >
                        <option hidden value=''>{t('messages.selectYourOption')}</option>
                        <option selected={extendedWarrantyClaim?.status == "paymentInitiated"} value="setToPaymentInitiated">{"Payment Initiated"}</option>
                        <option selected={extendedWarrantyClaim?.status == "paymentInProgress"} value="setToPaymentInProgress">{"Payment In Progress"}</option>
                        <option selected={extendedWarrantyClaim?.status == "paymentCompleted"} value="setToPaymentCompleted">{"Payment Completed"}</option>
                      </select>
                    </div>
                  </div>
                )}

                {extendedWarrantyClaim?.beneficiary?.paymentMethod == "bankTransfer" && extendedWarrantyClaim?.status == "reimbursementRequested" && (
                  <div className="col-sm-12">
                    <div className="form-group">
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        id="initiate-payment-button"
                        onClick={() => handleUpdateStatus({ values: formik.values, status: 'setToPaymentInitiated', isSaveRequired: true })}
                      >
                        <i className="fa fa-location-arrow" aria-hidden="true"></i>
                        {" Payment Initiated"}
                      </button>
                    </div>
                  </div>
                )}

              </div>
            </div>
          </div>
        </div>
      </div >

    </>
  );
};

export default UpdateStatusView;
